// import consumer from "./consumer"

// consumer.subscriptions.create("RequestsChannel", {
//   connected: function() {
//     console.log("Connected")
//     // Called when the subscription is ready for use on the server
//   },

//   disconnected: function() {
//     console.log("Disconnected")
//     // Called when the subscription has been terminated by the server
//   },

//   received: function(data) {
//     console.log("Received: ")
//     console.log(data)

//     if (data.command === 'refresh') {
//       var cur = window.location.href.split("/").pop();
//       // In these modes, simply refresh the page
//       if (cur === 'requests') {
//         window.location.href = '/requests' 
//       }
//       else if (cur === 'monitor') {
//         window.location.href = '/fleets/monitor'
//       }
//     }
//   }
// })