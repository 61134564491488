import React from 'react'

const FleetsTesting = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          Patient Medication Requests
        </div>
        <div className="card-body">
          <code className="highlighter-red">Test Sent!</code>
        </div>
      </div>
    </>
  )
}

export default FleetsTesting
