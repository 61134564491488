//import TheContent from './TheContent'
import TheFooter from './TheFooter'
import TheLayout from './TheLayout'
import TheSidebar from './TheSidebar'

export {
  TheLayout,
  TheFooter,
  TheSidebar
}
