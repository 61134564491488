import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

// Containers
const TheLayout = React.lazy(() => import('./TheLayout'));

class Main extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={<div>Loading... </div>}>
            <Switch>
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default Main;